import React from "react";
import SideBar from "../../Components/Layout/SideBar";
import Navbar from "../../Components/Layout/Header";
import styles from "./styles.css";
import { useAppContext } from "../../Services/Providers/AppContextProvider";

export default function Layout(props) {
  const { appContextObj } = useAppContext();
  const { currentTabIndex } = appContextObj;
  return (
    <div className={styles.layout}>
      <Navbar />
      <SideBar {...props} />
      <button onClick={props.onNext} className="submit" type="submit">
        {currentTabIndex === 7 ? "Submit" : "Next"}
      </button>
    </div>
  );
}
