import React from "react";
import styles from "./styles";

export default function EmergencyContact(props) {
  const { Input } = props.thisParent;
  return (
    <React.Fragment>
      <div style={styles.root}>
        <div style={{ marginRight: 10, marginLeft: 10 }}>
          <Input
            formType="specialInfo"
            style={styles.input}
            label={
              "Any special information (fears, family problems or changes, personality traits, playing, eating or sleeping habits or emotional needs of the child) that would be helpful to caregivers."
            }
            name={"SpecialInformation"}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
