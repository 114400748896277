export default {
  root: {
    minHeight: "150vh",
  },
  formContent: { backgroundColor: "#ffffff", border: "1px solid #F2F2F2", margin: 20, padding: 20, borderRadius: 10 },
  titleContent: { marginBottom: "20px" },
  title: {
    fontWeight: "600",
  },
  input: { marginTop: "15px" },
  infoText: {
    textAlign: "left",
    fontSize: 30,
  },
  overlayTextInput: {
    borderColor: "#000",
    background: "#fff0 ",
  },
  ClosetButton: {
    marginRight: "20px",
    borderRadius: "5px",
    border: "none",
    padding: "25px",
    fontSize: "25px",
    color: "#fff",
    backgroundColor: "#aaa",
  },
  SubmitButton: {
    backgroundColor: "#F05C63",
    color: "#fff",
    borderRadius: "5px",
    border: "none",
    padding: "25px",
    fontSize: "25px",
    width: "100%",
  },
};
