import React, { useState } from "react";
import styles from "./styles";

const Input = ({ error, helperText, options, style, title, thisParent, onCheck, formType, name, inputName }) => {
  const [show, setshow] = useState(false);
  const { Input } = thisParent;
  const isChecked = thisParent.getAllState.medical[name].value;

  const onShow = (label) => {
    if (label === "Yes") {
      setshow(true);
      onCheck(label);
    } else {
      setshow(false);
      onCheck(label);
    }
  };
  console.log(isChecked);
  return (
    <div style={styles.root(style)}>
      <p style={styles.label}>{title}</p>
      <div style={{ marginLeft: "10px", width: "150px", marginTop: "20px", justifyContent: "space-around", display: "flex" }}>
        {options.map(({ label, value }) => (
          <div style={{ marginRight: 10, display: "flex", flexDirection: "row" }} key={label}>
            <input onClick={() => onShow(label)} style={{ marginRight: 5 }} type="radio" name={name} checked={isChecked === value} />
            <p>{label}</p>
          </div>
        ))}
      </div>
      {(show || isChecked == 1) && <Input style={{ marginTop: 20 }} formType={formType} name={inputName} label={"Please specify ..."} />}
      {!!error && <p style={styles.errorText}>{helperText}</p>}
    </div>
  );
};

export default Input;
