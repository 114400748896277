import React from "react";
import styles from "./styles";

const Input = ({ onError, label, onChange, helperText, error, name, style, value, formType, inputStyle, labelStyle, placeholder, tail = false }) => {
  return (
    <div style={styles.root(style)}>
      <p style={styles.label(labelStyle)}>{label}</p>
      <div style={styles.inputCounter}>
        <input
          placeholder={placeholder}
          onChange={(e) => onChange(name, e.target.value, formType)}
          onBlur={(e) => onChange(name, e.target.value, formType)}
          style={styles.input(inputStyle)}
          type={"text"}
          value={value}
        />
        {tail && <p style={styles.tail}>{tail}</p>}
        {!!error && <p style={styles.errorText}>{helperText}</p>}
      </div>
    </div>
  );
};

export default Input;
