import React from "react";
import each from "lodash/each";
import reduce from "lodash/reduce";
import set from "lodash/set";
import get from "lodash/get";
import find from "lodash/find";
import isUndefined from "lodash/isUndefined";
import isNull from "lodash/isNull";
import pickBy from "lodash/pickBy";

import { Input, RadioButtonGroup } from "Components/Layout";
import Dropdown from "Components/Layout/DropDown";

import Types from "./Types";
import FormState from "./FormState";
import FieldState from "./FieldState";

const blackhole = () => {};

const ENABLE_ERROR_MESSAGE_KEY = "enableFormErrorMessage";
const ERROR_MESSAGE_KEY = "formErrorMessage";

class Builder extends React.Component {
  constructor(props) {
    super(props);

    this.FieldState = FieldState;
    this.types = Types;
    this._listeners = {};
  }

  componentDidMount() {
    this.validateForm();
  }

  init(restState) {
    this.state = {
      ...reduce(
        restState,
        (fieldsState, field, name) => {
          fieldsState = {
            ...fieldsState,
            [name]: new FormState(field),
          };
          return fieldsState;
        },
        {}
      ),
    };

    this._initComponents();
  }

  /**
   ***********************************************
   * Helpers
   * *********************************************
   */
  showFormErrors(formType, callback) {
    this.stateUpdater({
      fieldUpdater: () => ({
        showErrors: true,
      }),
      stateUpdaters: [
        () => ({
          [ENABLE_ERROR_MESSAGE_KEY]: true,
        }),

        (state) => this._getFormGlobalErrorMessageState(state, formType),
      ],
      callback,
      formType,
    });
  }

  hideFormErrors(formType, callback) {
    this.stateUpdater({
      fieldUpdater: () => ({
        showErrors: false,
      }),
      stateUpdaters: [
        () => ({
          [ENABLE_ERROR_MESSAGE_KEY]: false,
        }),

        (state) => this._getFormGlobalErrorMessageState(state, formType),
      ],
      callback,
    });
  }

  updateFormValues(formType, form = {}, callback = blackhole) {
    const newFormState = reduce(
      this.formState(formType),
      (fieldsState, field, name) => {
        if (name in form === false) {
          return fieldsState;
        }

        const value = isNull(form[name]) ? this.formValues(formType)[name] : field.type(form[name]);
        return set(fieldsState, name, new FieldState({ name, ...field, value }));
      },
      {}
    );
    // console.log({
    //   [formType]: {
    //     ...this.formState(formType),
    //     ...newFormState,
    //   },
    // });

    this.setState(
      {
        [formType]: {
          ...this.formState(formType),
          ...newFormState,
        },
      },
      () => {
        this.validateForm(callback);
      }
    );
  }

  addFormFields(formType, fields, callback = blackhole) {
    const newFieldsState = reduce(fields, (fieldsState, field, name) => set(fieldsState, name, new FieldState({ name, ...field })), {});
    // console.log({
    //   [formType]: {
    //     ...this.formState(formType),
    //     ...newFieldsState,
    //   },
    // });
    this.setState(
      {
        [formType]: {
          ...this.formState(formType),
          ...newFieldsState,
        },
      },
      () => {
        this.validateForm();
        callback();
      }
    );
  }

  removeFormFields = (formType, fields = [], callback = blackhole) => {
    const newFormState = pickBy(this.state[formType], (field, name) => fields.indexOf(name) === -1);
    this.setState(
      {
        [formType]: newFormState,
      },
      () => {
        this.validateForm();
        callback();
      }
    );
  };

  /**
   ***********************************************
   * Core/Private Methods
   * *********************************************
   */
  stateUpdater({ fieldUpdater, stateUpdaters, callback, formType }) {
    callback = callback || blackhole;
    fieldUpdater = fieldUpdater || blackhole;
    stateUpdaters = stateUpdaters || [];
    const newFormState = reduce(
      this.formState(formType),
      (formState, field, name) => {
        return set(formState, name, this.getUpdatedFieldState(name, fieldUpdater, formType));
      },
      {}
    );
    this.setState({ [formType]: newFormState }, callback);
  }

  validateForm(callback = blackhole) {
    Object.keys(this.state).map((formType) => {
      this.stateUpdater({ callback, fieldUpdater: (name, value) => ({ errors: this.getFieldErrors(name, value, formType) }), callback, formType });
    });
  }

  getFieldState(name, formType) {
    return get(this.formState(formType), name, null);
  }

  getUpdatedFieldState(name, fieldUpdater, formType) {
    const fieldState = this.getFieldState(name, formType);

    // list
    if (Array.isArray(fieldState.value)) {
      const updatedChildrenState = [];

      // iterate over children and update list items
      for (let i = 0; i < fieldState.value.length; i++) {
        updatedChildrenState.push(this.getUpdatedFieldState(`${name}.value.${i}`, fieldUpdater, formType));
      }
      return {
        ...fieldState,
        ...fieldUpdater(name, updatedChildrenState, formType),
        value: updatedChildrenState,
      };
    }

    // object
    return {
      ...fieldState,
      ...fieldUpdater(name, fieldState.value),
    };
  }

  getFieldErrors(name, value, formType) {
    const field = this.state[formType][name];

    const { validators } = field;

    const errors = [];

    if (!field.validators) {
      return errors;
    }

    for (let index = 0, len = validators.length; index < len; index++) {
      const validationResult = validators[index].validate(value, field, () => this.formState(formType));

      if (validationResult.valid === false) {
        errors.push(validationResult);
      }
    }
    return errors;
  }

  getFieldHasError(name, formType) {
    return Boolean(this.getFieldState(name, formType).showErrors && this.getFieldState(name, formType).errors.length);
  }

  _initComponents() {
    const FieldsComponents = {
      Input: Input,
      DropDown: Dropdown,
      RadioButtonGroup: RadioButtonGroup,
    };

    // Fields Components
    each(FieldsComponents, (WrappedComponent, FieldName) => {
      this[FieldName] = (props) => {
        if (this.getFieldState(props.name, props.formType)) {
          return <WrappedComponent {...props} {...this._makeCommonFieldProps(props)} />;
        }
        return null;
      };
    });
  }

  _makeCommonFieldProps(props) {
    let value = this._getFieldKeyValue(props.name, "value", props.formType);

    if (isUndefined(value) || isNull(value)) {
      value = "";
    }

    return {
      value,
      required: this._getFieldIsRequired(props.name, props.formType),
      error: this.getFieldHasError(props.name, props.formType),
      helperText: this._getFieldHelperMessage(props.name, props.helperText, props.formType),
      onChange: this._onFieldChange(props.onChange, props.formType),
    };
  }

  _getFieldKeyValue(name, key, formType) {
    return this.getFieldState(name, formType)[key];
  }

  _getFieldIsRequired(name, formType) {
    return Boolean(find(this.getFieldState(name, formType).validators, (v) => v.key === "REQUIRED"));
  }

  _getFieldHelperMessage(name, helperMessage = "", formType) {
    if (this.getFieldHasError(name, formType)) {
      return get(this.getFieldState(name, formType), "errors.0.message");
    }

    return helperMessage;
  }

  _getFormGlobalErrorMessageState(state, formType) {
    const isFormValid = this._isFormValid(state[formType]);
    let errorMessageValue = false;

    if (isFormValid) {
      errorMessageValue = false;

      // show error message if form submitted
    } else if (state[ENABLE_ERROR_MESSAGE_KEY]) {
      errorMessageValue = "Error !!";

      // form is invalid BUT 'enable' is false
    } else {
      errorMessageValue = false;
    }

    return {
      [ERROR_MESSAGE_KEY]: errorMessageValue,
    };
  }

  _onFieldChange = (callback = blackhole) => (name, value, formType, ...restParams) => {
    const field = this.getFieldState(name, formType);
    const newFormState = set(this.state[formType], name, {
      ...field,
      errors: this.getFieldErrors(name, field.type(value), formType),
      value: field.type(value),
      showErrors: true,
    });

    let newState = {
      ...this.state,
      [formType]: newFormState,
    };

    newState = {
      ...newState,
      ...this._getFormGlobalErrorMessageState(newState, formType),
    };

    this.setState(newState, () => {
      this.notify("field-changed", name, value);
      callback(this.getFieldState(name, formType), ...restParams);
    });
  };

  _isFormValid(formState) {
    return reduce(formState, (valid, field) => valid && field.errors.length === 0, true);
  }

  _broadcastFormStatusChange() {
    return setTimeout(() => {
      if (this.isFormValid) {
        this.notify("formValid");
      } else {
        this.notify("formInvalid");
      }
    });
  }

  on(event, listener) {
    this._listeners[event] = this._listeners[event] || [];
    this._listeners[event].push(listener);
  }

  notify(event, ...args) {
    this._listeners[event] = this._listeners[event] || [];
    this._listeners[event].forEach((listener) => listener(...args));
  }

  formState(formType) {
    return this.state[formType];
  }

  isFormValid(formType) {
    return this._isFormValid(this.formState(formType));
  }

  get getAllState() {
    return this.state;
  }

  formValues(formType) {
    // reduces form state to form values
    // and only pick non-ui fields

    //console.log("AST", this.formState(formType));

    return reduce(
      this.formState(formType),
      (formValue, field, name) => {
        if (field.UIOnly) {
          return formValue;
        }

        const convertedValue = field.value ? field.type(field.value) : field.value;

        return set(formValue, name, convertedValue);
      },
      {}
    );
  }
}

export default Builder;
