import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Menu, Segment, Sidebar, Icon } from "semantic-ui-react";

import { useAppContext } from "../../../Services/Providers/AppContextProvider";

import styles from "./styles";
import "./styles.css";

const Items = [
  { name: "child", textIcon: require("../../../Images/childT.png"), icon: require("../../../Images/child.png") },
  { name: "mom", textIcon: require("../../../Images/momT.png"), icon: require("../../../Images/mom.png") },
  { name: "dad", textIcon: require("../../../Images/dadT.png"), icon: require("../../../Images/dad.png") },
  { name: "maritalStatus", textIcon: require("../../../Images/MsT.png"), icon: require("../../../Images/Ms.png") },
  { name: "siblings", bigger: true, textIcon: require("../../../Images/siblingsT.png"), icon: require("../../../Images/siblings.png") },
  { name: "emergencyContact", bigger: true, textIcon: require("../../../Images/EmT.png"), icon: require("../../../Images/Em.png") },
  { name: "medical", textIcon: require("../../../Images/medT.png"), icon: require("../../../Assets/Images/med-info.png") },
  { name: "specialinfo", bigger: true, textIcon: require("../../../Assets/Images/SP-infoT.png"), icon: require("../../../Assets/Images/SP-info.png") },
];

export default function SideBar({ children }) {
  const { appContextObj, setAppContext } = useAppContext();
  const { currentTabIndex, isComplete, isSmall, isTouched } = appContextObj;

  let history = useHistory();

  const onClickHandler = useCallback(
    (name, index) => () => {
      setAppContext((oldObj) => ({
        ...oldObj,
        currentTabIndex: index,
      }));
      history.push(`/admission/${name}`);
    },
    [history, appContextObj]
  );

  const onClickAtSidebar = useCallback(
    () => () => {
      if (!isSmall) return;
      setAppContext((oldObj) => ({
        ...oldObj,
        isSmall: false,
      }));
    },
    [appContextObj]
  );
  const onClickAtScreen = useCallback(
    () => () => {
      if (isSmall) return;
      setAppContext((oldObj) => ({
        ...oldObj,
        isSmall: true,
      }));
    },
    [appContextObj]
  );

  const ulStyle = isSmall ? "mini" : "sideBar";
  return (
    <Sidebar.Pushable
      style={{
        width: "99%",
        position: "fixed",
        overflow: "revert",
        margin: "0px",
        marginRight: "15px",
        border: "0px",
      }}
      as={Segment}
    >
      <Sidebar as={Menu} animation="overlay" icon="labeled" inverted vertical visible style={styles.root(isSmall)}>
        <div style={styles.content} onClick={onClickAtSidebar()}>
          <ul className={`${ulStyle}`}>
            {Items.map((item, index) => {
              const active = index === currentTabIndex ? "item-list-active" : "op";
              return (
                <li className={`item-list ${active}`} onClick={onClickHandler(item.name, index)} key={item.name}>
                  <div className="item-content">
                    <img style={styles.icon} src={item.icon} alt="menu icon" />
                    {!isSmall && <img style={styles.iconT(!!item.bigger)} src={item.textIcon} alt="menu text" />}
                  </div>
                  <Icon className="iconCheck" name="check circle" size="big" color={isComplete[index] && isTouched[index] ? "green" : "grey"} />
                </li>
              );
            })}
          </ul>
        </div>
      </Sidebar>
      <Sidebar.Pusher style={{ overflow: "scroll", height: "100vh", minHeight: "110vh", marginBottom: "50px" }}>
        <div style={styles.screen(isSmall)} onClick={onClickAtScreen()}>
          {children}
        </div>
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  );
}
