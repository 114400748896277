import React from "react";

import styles from "./styles";

export default function BDate({ label, children }) {
  return (
    <div>
      <p style={styles.label}>{label}</p>
      <div style={styles.container}>{children}</div>
    </div>
  );
}
