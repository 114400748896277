export default {
  container: {
    display: "flex",
    flexDierction: "row",
    flexWrap: "wrap",
  },
  label: {
    fontWeight: "normal",
    fontSize: "17px",
    color: "#00000080",
    fontFamily: "Roboto",
    marginTop: 15,
  },
};
