import React from "react";
import styles from "./styles";

export default function Mom(props) {
  const { Input } = props.thisParent;
  var data = [
    {
      label: "First Name",
      formType: "mom",
      name: "MomFirstName",
    },
    {
      label: "Middle Name",
      formType: "mom",
      name: "MomMiddleName",
    },
    {
      label: "Last Name",
      formType: "mom",
      name: "MomLastName",
    },
    {
      label: "Mobile Number",
      formType: "mom",
      name: "MomMobileNumber",
    },
    {
      label: "Email",
      formType: "mom",
      name: "MomEmail",
    },
    {
      label: "Graduated From",
      formType: "mom",
      name: "MomGraduatedFrom",
    },
    {
      label: "Occupation",
      formType: "mom",
      name: "MomOccupation",
    },
    {
      label: "Work Number",
      formType: "mom",
      name: "MomWorkNumber",
    },
  ];

  return (
    <div style={styles.root}>
      <div style={{ marginRight: 10, marginLeft: 10, background: "#fff", marginBottom: 40 }}>
        {data.map((item, index) => {
          return <Input style={styles.input} {...item} />;
        })}
      </div>
    </div>
  );
}
