import React, { Component } from "react";
import { Frame } from "../../../Components/Layout";
import styles from "./styles";

export default class EmergencyContact extends Component {
  render() {
    const { Input } = this.props.thisParent;
    return (
      <div style={styles.root}>
        <div style={{ marginRight: 10, marginLeft: 10 }}>
          <Frame title={"Emergency Contact 1"}>
            <Input formType="emergencyContact" style={styles.input} label={"Name"} name={"name1"} />
            <Input formType="emergencyContact" style={styles.input} label={"Relation to Child"} name={"relationToChild1"} />
            <Input formType="emergencyContact" style={styles.input} label={"Mobile Number"} name={"mobileNumber1"} />
          </Frame>
          <Frame title={"Emergency Contact 2"}>
            <Input formType="emergencyContact" style={styles.input} label={"Name"} name={"name2"} />
            <Input formType="emergencyContact" style={styles.input} label={"Relation to Child"} name={"relationToChild2"} />
            <Input formType="emergencyContact" style={styles.input} label={"Mobile Number"} name={"mobileNumber2"} />
          </Frame>
        </div>
      </div>
    );
  }
}
