import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
// import { composeWithDevTools } from 'remote-redux-devtools';

export default (rootReducer, rootSaga) => {
  const middleware = [];
  const enhancers = [];

  // Saga
  const sagaMiddleware = createSagaMiddleware();
  middleware.push(sagaMiddleware);

  // Push middlewares
  enhancers.push(applyMiddleware(...middleware));

  //const store = createStore(rootReducer, applyMiddleware(...middleware));
  // const store = createStore(rootReducer, composeWithDevTools(...enhancers));
  const store = createStore(rootReducer, compose(...enhancers));
  // const store = createStore(
  //   rootReducer,
  //   compose(...enhancers, !!window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
  // );
  // kick off root saga

  // const composeEnhancers =
  //   typeof window === 'object' &&
  //     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
  //     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
  //       // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
  //     }) : compose;

  // const enhancer = composeEnhancers(
  //   applyMiddleware(...middleware),
  //   ...enhancers
  // );
  // const store = createStore(rootReducer, enhancer);


  sagaMiddleware.run(rootSaga);

  return store;
};
