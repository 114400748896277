import React from 'react';
import { Dropdown } from 'semantic-ui-react';

import styles from './styles';

export default function DropDown({
  label,
  name,
  value,
  error,
  helperText,
  placeholder,
  disabled,
  onChange,
  formType,
  list,
  style,
}) {



  return (
    <div >
      <p style={styles.label}>{label}</p>
      <Dropdown
        //  placeholder={placeholder}
        search
        selection
        options={list}
        text={value || list[0].text || ' '}
        onChange={(e, value) => onChange(name, value.value, formType)}
        onBlur={(e) => onChange(name, value, formType)}
        style={styles.selected}
      />
      {!!error && <p style={styles.errorText}>{helperText}</p>}
    </div>
  )
}
