import React from "react";

import "./styles.css";

export default function Navbar({ children }) {
  return (
    <div className="header">
      <img src={require("../../../Images/logo-light.png")} alt="header-logo" />
    </div>
  );
}
