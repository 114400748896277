import React from "react";
import styles from "./styles";

export default function Frame({ children, title }) {
  return (
    <div style={styles.formContent}>
      {!!title && (
        <div style={styles.titleContent}>
          <p style={styles.title}>{title}</p>
        </div>
      )}
      {children}
    </div>
  );
}
