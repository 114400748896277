import React from "react";
import styles from "./styles";
import { isRequired, minLength } from "Services/Validators";

export default function EmergencyContact(props) {
  var Rad1 = {
    options: [
      { label: "Yes", value: 1 },
      { label: "No", value: 0 },
    ],
    title: "Does your child have any special medical conditions?",
  };
  var Rad2 = {
    options: [
      { label: "Yes", value: 1 },
      { label: "No", value: 0 },
    ],
    title: "Does your child have any allergies?",
  };
  var Rad3 = {
    options: [
      { label: "Yes", value: 1 },
      { label: "No", value: 0 },
    ],
    title: "Has he/she received services for learning disorders/special assistance?",
  };
  const { Input, RadioButtonGroup } = props.thisParent;
  const onCheck1 = (label) => {
    if (label === "Yes") {
      props.thisParent.addFormFields("medical", {
        MedicalConditions: {
          type: props.thisParent.types.string,
          validators: [isRequired(), minLength(3)],
        },
        HasMedicalConditions: {
          type: props.thisParent.types.string,
          validators: [],
          value: 1,
        },
      });
    } else {
      props.thisParent.addFormFields("medical", {
        MedicalConditions: {
          type: props.thisParent.types.string,
          validators: [],
          value: "",
        },
        HasMedicalConditions: {
          type: props.thisParent.types.string,
          validators: [],
          value: 0,
        },
      });
    }
  };
  const onCheck2 = (label) => {
    if (label === "Yes") {
      props.thisParent.addFormFields("medical", {
        Allergies: {
          type: props.thisParent.types.string,
          validators: [isRequired(), minLength(3)],
        },
        HasAllergies: {
          type: props.thisParent.types.string,
          validators: [],
          value: 1,
        },
      });
    } else {
      props.thisParent.addFormFields("medical", {
        Allergies: {
          type: props.thisParent.types.string,
          validators: [],
          value: "",
        },
        HasAllergies: {
          type: props.thisParent.types.string,
          validators: [],
          value: 0,
        },
      });
    }
  };
  const onCheck3 = (label) => {
    if (label === "Yes") {
      props.thisParent.addFormFields("medical", {
        LearningDisorders: {
          type: props.thisParent.types.string,
          validators: [isRequired(), minLength(3)],
        },
        HasLearningDisorders: {
          type: props.thisParent.types.string,
          validators: [],
          value: 1,
        },
      });
    } else {
      props.thisParent.addFormFields("medical", {
        LearningDisorders: {
          type: props.thisParent.types.string,
          validators: [],
          value: "",
        },
        HasLearningDisorders: {
          type: props.thisParent.types.string,
          validators: [],
          value: 0,
        },
      });
    }
  };
  return (
    <div style={styles.root}>
      <div style={{ marginRight: 10, marginLeft: 10 }}>
        <Input formType="medical" style={styles.input} label={"Doctor's Name"} name={"DoctorName"} />
        <Input formType="medical" style={styles.input} label={"Mobile Number"} name={"DoctorMobileNumber"} />
        <RadioButtonGroup name={"HasMedicalConditions"} formType="medical" inputName={"MedicalConditions"} onCheck={onCheck1} thisParent={props.thisParent} style={styles.radioButtonGroup} {...Rad1} />
        <RadioButtonGroup name={"HasAllergies"} formType="medical" inputName={"Allergies"} onCheck={onCheck2} thisParent={props.thisParent} style={styles.radioButtonGroup} {...Rad2} />
        <RadioButtonGroup name={"HasLearningDisorders"} formType="medical" inputName={"LearningDisorders"} onCheck={onCheck3} thisParent={props.thisParent} style={styles.radioButtonGroup} {...Rad3} />
      </div>
    </div>
  );
}
