import React, { Component } from "react";
import BDate from "Components/Layout/BDate";
import { generateDates, ChildGender } from "Services/Common";

import styles from "./styles";

const { Days, Months, Years } = generateDates();

export default class Child extends Component {
  render() {
    const { Input, DropDown } = this.props.thisParent;
    return (
      <div style={styles.root}>
        <div style={{ marginRight: 10, marginLeft: 10 }}>
          <Input formType="child" style={styles.input} label={"First Name"} name={"ChildFirstName"} />
          <Input formType="child" style={styles.input} label={"Middle Name"} name={"ChildMiddleName"} />
          <Input formType="child" style={styles.input} label={"Last Name"} name={"ChildLastName"} />
          <DropDown formType="child" style={styles.input} label={"Child's Gender"} placeholder={"Child's Gender"} name={"ChildGender"} list={ChildGender} />
          <BDate label={"Birth Date"}>
            <DropDown formType="child" style={styles.input} placeholder={"Day"} name={"ChildDay"} list={Days} />
            <DropDown formType="child" style={styles.input} placeholder={"Month"} name={"ChildMonth"} list={Months} />
            <DropDown formType="child" style={styles.input} placeholder={"Year"} name={"ChildYear"} list={Years} />
          </BDate>
          <Input formType="child" style={styles.input} label={"Home Address"} name={"ChildHomeAddress"} />
          <Input formType="child" style={styles.input} label={"Home Number"} name={"ChildHomeNumber"} />
        </div>
      </div>
    );
  }
}
