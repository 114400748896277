import Colors from "../../../Theme/Colors";

export default {
  root: (isSmall = false) => ({
    backgroundColor: Colors.gray,
    minHeight: "100vh",
    width: isSmall ? "115px" : "325px",
    transition: "linear 200ms",
  }),
  content: {
    height: "100%",
    overflow: "hidden",
    position: "fixed",
  },
  icon: {
    width: 40,
    //  height: 40,
    // paddingLeft: 12,
  },
  iconT: (bigger) => ({
    marginLeft: 20,
    height: bigger ? 18 : 15,
    width: "auto",
  }),
  screen: (isSmall = false) => ({
    marginLeft: isSmall ? 130 : 380,
    marginTop: 115,
    minHeight: "100vh",
  }),
};
