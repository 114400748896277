import React from 'react';
import { useAppContext } from "Services/Providers/AppContextProvider";

function WithContext(WrappedComponent) {

    const appContext = useAppContext();

    return <WrappedComponent appContext={appContext} />;

}

export default WithContext;