import isNaN from "lodash/isNaN";

import { Validator } from "Components/Form";

export const isRequired = (message = "** This field is required.") =>
  new Validator({
    key: "REQUIRED",
    message,
    validator: (value) => (value === null || value === undefined || isNaN(value) ? false : `${value}`.trim().length > 0),
  });

export const minLength = (length, message) =>
  new Validator({
    key: "MIN_LENGTH",
    message: message || `** Minimum length is ${length}`,
    validator: (value) => `${value}`.length >= length,
  });

export const maxLength = (length, message) =>
  new Validator({
    key: "MAX_LENGTH",
    message: message || `** Maximum length is ${length}`,
    validator: (value) => `${value}`.length <= length,
  });

export const exactLength = (length, message) =>
  new Validator({
    key: "EXACT_LENGTH",
    message: message || `** Field length should be ${length}`,
    validator: (value) => `${value}`.length === length,
  });

export const onlyNumbers = (message = "** Only numbers are valid.") =>
  new Validator({
    key: "ONLY_NUMBERS",
    message,
    validator: (value) => /^\d*$/gi.test(`${value}`),
  });

export const matches = (otherValue, message = "** Not matched") =>
  new Validator({
    key: "MATCHES",
    message,
    validator: (value) => value === otherValue(),
  });

export const notEqual = (otherValue, message = "") =>
  new Validator({
    key: "NOT_EQUAL",
    message,
    validator: (value) => value !== otherValue(),
  });

export const emailAddress = (message = "** Invalid email address") =>
  new Validator({
    key: "EMAIL",
    message,
    validator: (value) => /^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64}$/gi.test(`${value}`),
  });
