import { takeEvery, takeLatest, all } from 'redux-saga/effects';

/* ------------- Types ------------- */
import { StartupTypes } from '../Actions/Startup';
import { AccountTypes } from '../Actions/Account';
import { EntityTypes } from '../Actions/Entity';

/* ------------- Sagas ------------- */
import startupSagas from './Startup';
import accountSagas from './Account';
import entitySagas from './Entity';

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  yield all([
    takeLatest(StartupTypes.STARTUP, startupSagas.startup),

    takeLatest(AccountTypes.SET_TOKEN, accountSagas.setToken),
    takeLatest(AccountTypes.CHECK_STATUS, accountSagas.checkStatus),
    takeLatest(AccountTypes.GET_PROFILE, accountSagas.getProfile),
    takeLatest(AccountTypes.LOGOUT, accountSagas.logout),

    takeEvery(EntityTypes.POST, entitySagas.post),
    takeEvery(EntityTypes.GET, entitySagas.get),
    takeLatest(EntityTypes.GET_LATEST, entitySagas.get)
  ]);
}
