import React, { useState, createContext, useContext } from "react";
const AppContextData = {
  //Global state
  haveBranch: null,
  showSubmitOverlay: false,
  checked: false,
  branches: [],
  submitting: false,

  // Sidebar state
  isSmall: false,
  currentTabIndex: 0,
  isComplete: [0, 0, 0, 0, 0, 0, 0, 0],
  isTouched: [0, 0, 0, 0, 0, 0, 0, 0],

  //Siblings state
  SiblingsForm: [],
};

// initializing context
export const AppContext = createContext(AppContextData);

// setting a helper function to use context
export const useAppContext = () => useContext(AppContext);

export function AppContextProvider({ children }) {
  const [appContextObj, setAppContext] = useState(AppContextData);
  return <AppContext.Provider value={{ appContextObj, setAppContext }}>{children}</AppContext.Provider>;
}
