import React, { Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Modal, Checkbox, Button } from "semantic-ui-react";
import Layout from "../Screens/Layout";
import { LayoutSplashScreen } from "../Services/SplashScreen";
import Landing from "../Screens/Landing";
import { AppContext } from "../Services/Providers/AppContextProvider";
import FormBuilder from "Components/Form";
import { isRequired, minLength, emailAddress, maxLength, notEqual, onlyNumbers, exactLength } from "Services/Validators";
import Medical from "Screens/Information/Medical";
import Dad from "Screens/Information/Dad";
import Child from "Screens/Information/Child";
import Mom from "Screens/Information/Mom";
import MaritalStatus from "Screens/Information/MaritalStatus";
import Siblings from "Screens/Information/Siblings";
import EmergencyContact from "Screens/Information/EmergencyContact";
import Special from "Screens/Information/Special";
import styles from "./styles";
import { asEntity } from "Hoc";

const EmptyPage = () => (
  <dev style={{ position: "absolute", top: "50px", left: "44%" }}>
    <h4 style={{}}> 404 not found! </h4>
  </dev>
);
class Routes extends FormBuilder {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.init({
      child: {
        ChildFirstName: {
          type: this.types.string,
          validators: [isRequired(), minLength(3), maxLength(20)],
        },
        ChildMiddleName: {
          type: this.types.string,
          validators: [isRequired(), minLength(3), maxLength(20)],
        },
        ChildLastName: {
          type: this.types.string,
          validators: [isRequired(), minLength(3), maxLength(20)],
        },
        ChildHomeAddress: {
          type: this.types.string,
          validators: [isRequired(), minLength(8)],
        },
        ChildHomeNumber: {
          type: this.types.string,
          validators: [
            onlyNumbers(),
            maxLength(20),
            // notEqual(() => this.formValues("emergencyContact").mobileNumber2, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("emergencyContact").mobileNumber1, "** Please enter a different phone number"),
            //notEqual(() => this.formValues("mom").MomMobileNumber, "** Please enter a different phone number"),
            //notEqual(() => this.formValues("mom").MomWorkNumber, "** Please enter a different phone number"),
            //notEqual(() => this.formValues("dad").DadMobileNumber, "** Please enter a different phone number"),
            //notEqual(() => this.formValues("dad").DadWorkNumber, "** Please enter a different phone number"),
            //notEqual(() => this.formValues("medical").DoctorMobileNumber, "** Please enter a different phone number"),
          ],
        },
        ChildGender: {
          type: this.types.string,
          validators: [isRequired()],
        },
        ChildDay: {
          type: this.types.string,
          validators: [isRequired()],
        },
        ChildMonth: {
          type: this.types.string,
          validators: [isRequired()],
        },
        ChildYear: {
          type: this.types.string,
          validators: [isRequired()],
        },
      },
      mom: {
        MomFirstName: {
          type: this.types.string,
          validators: [isRequired(), minLength(3), maxLength(20)],
        },
        MomMiddleName: {
          type: this.types.string,
          validators: [isRequired(), minLength(3), maxLength(20)],
        },
        MomLastName: {
          type: this.types.string,
          validators: [isRequired(), minLength(3), maxLength(20)],
        },
        MomMobileNumber: {
          type: this.types.string,
          validators: [
            isRequired(),
            onlyNumbers(),
            minLength(11),
            maxLength(20),
            // notEqual(() => this.formValues("emergencyContact").mobileNumber2, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("emergencyContact").mobileNumber1, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("mom").MomWorkNumber, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("dad").DadMobileNumber, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("dad").DadWorkNumber, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("child").ChildHomeNumber, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("medical").DoctorMobileNumber, "** Please enter a different phone number"),
          ],
        },
        MomEmail: {
          type: this.types.string,
          validators: [isRequired(), emailAddress()],
        },
        MomGraduatedFrom: {
          type: this.types.string,
          validators: [minLength(3)],
        },
        MomOccupation: {
          type: this.types.string,
          validators: [minLength(3)],
        },
        MomWorkNumber: {
          type: this.types.string,
          validators: [
            onlyNumbers(),
            exactLength(11),
            // notEqual(() => this.formValues("emergencyContact").mobileNumber2, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("emergencyContact").mobileNumber1, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("mom").MomWorkNumber, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("dad").DadMobileNumber, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("dad").DadWorkNumber, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("child").ChildHomeNumber, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("medical").DoctorMobileNumber, "** Please enter a different phone number"),
          ],
        },
      },
      dad: {
        DadFirstName: {
          type: this.types.string,
          validators: [isRequired(), minLength(3), maxLength(20)],
        },
        DadMiddleName: {
          type: this.types.string,
          validators: [isRequired(), minLength(3), maxLength(20)],
        },
        DadLastName: {
          type: this.types.string,
          validators: [isRequired(), minLength(3), maxLength(20)],
        },
        DadMobileNumber: {
          type: this.types.string,
          validators: [
            onlyNumbers(),
            maxLength(20),
            // notEqual(() => this.formValues("emergencyContact").mobileNumber2, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("emergencyContact").mobileNumber1, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("mom").MomMobileNumber, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("mom").MomWorkNumber, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("dad").DadWorkNumber, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("child").ChildHomeNumber, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("medical").DoctorMobileNumber, "** Please enter a different phone number"),
          ],
        },
        DadEmail: {
          type: this.types.string,
          validators: [isRequired(), emailAddress()],
        },
        DadGraduatedFrom: {
          type: this.types.string,
          validators: [minLength(3)],
        },
        DadOccupation: {
          type: this.types.string,
          validators: [minLength(3)],
        },
        DadWorkNumber: {
          type: this.types.string,
          validators: [
            onlyNumbers(),
            exactLength(11),
            // notEqual(() => this.formValues("emergencyContact").mobileNumber2, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("emergencyContact").mobileNumber1, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("mom").MomMobileNumber, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("mom").MomWorkNumber, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("dad").DadWorkNumber, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("child").ChildHomeNumber, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("medical").DoctorMobileNumber, "** Please enter a different phone number"),
          ],
        },
      },
      emergencyContact: {
        name1: {
          type: this.types.string,
          validators: [minLength(3), maxLength(20), isRequired()],
        },
        relationToChild1: {
          type: this.types.string,
          validators: [minLength(2), isRequired()],
        },
        mobileNumber1: {
          type: this.types.string,
          validators: [
            isRequired(),
            onlyNumbers(),
            minLength(11),
            maxLength(20),
            // notEqual(() => this.formValues("emergencyContact").mobileNumber2, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("mom").MomMobileNumber, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("mom").MomWorkNumber, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("dad").DadMobileNumber, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("dad").DadWorkNumber, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("child").ChildHomeNumber, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("medical").DoctorMobileNumber, "** Please enter a different phone number"),
          ],
        },
        name2: {
          type: this.types.string,
          validators: [minLength(3), maxLength(20), isRequired()],
        },
        relationToChild2: {
          type: this.types.string,
          validators: [minLength(2), isRequired()],
        },
        mobileNumber2: {
          type: this.types.string,
          validators: [
            isRequired(),
            onlyNumbers(),
            minLength(11),
            maxLength(20),
            // notEqual(() => this.formValues("emergencyContact").mobileNumber1, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("mom").MomMobileNumber, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("mom").MomWorkNumber, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("dad").DadMobileNumber, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("dad").DadWorkNumber, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("child").ChildHomeNumber, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("medical").DoctorMobileNumber, "** Please enter a different phone number"),
          ],
        },
      },
      medical: {
        DoctorName: {
          type: this.types.string,
          validators: [minLength(3), maxLength(20)],
        },
        DoctorMobileNumber: {
          type: this.types.string,
          validators: [
            minLength(11),
            maxLength(20),
            onlyNumbers(),
            // notEqual(() => this.formValues("emergencyContact").mobileNumber2, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("emergencyContact").mobileNumber1, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("mom").MomMobileNumber, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("mom").MomWorkNumber, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("dad").DadMobileNumber, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("dad").DadWorkNumber, "** Please enter a different phone number"),
            // notEqual(() => this.formValues("child").ChildHomeNumber, "** Please enter a different phone number"),
          ],
        },
        Allergies: {
          type: this.types.string,
          validators: [isRequired()],
        },
        MedicalConditions: {
          type: this.types.string,
          validators: [isRequired()],
        },
        LearningDisorders: {
          type: this.types.string,
          validators: [isRequired()],
        },
        HasAllergies: {
          type: this.types.string,
          validators: [isRequired()],
        },
        HasMedicalConditions: {
          type: this.types.string,
          validators: [isRequired()],
        },
        HasLearningDisorders: {
          type: this.types.string,
          validators: [isRequired()],
        },
      },
      specialInfo: {
        SpecialInformation: {
          type: this.types.string,
          validators: [minLength(3)],
        },
      },
      submitOverlay: {
        Signature: {
          type: this.types.string,
          showErrors: true,
          validators: [minLength(10), isRequired()],
        },
        RelationToChild: {
          type: this.types.string,
          showErrors: true,
          validators: [minLength(3), isRequired()],
        },
      },
      maritalStatus: {
        MaritalStatus: {
          type: this.types.string,
          validators: [isRequired()],
        },
      },
    });
  }
  componentDidUpdate() {
    this.onNext(false);
    // console.log("context:", this.context.appContextObj);
    // console.log("form stat", this.getAllState);
  }
  onSubmit = () => {
    const { setAppContext } = this.context;
    setAppContext((oldObj) => ({ ...oldObj, submitting: true }));
    var { haveBranch } = this.context.appContextObj;
    const child = this.formValues("child");
    // fix maritalStatus ( add DivorceLegalMatters if its not in the form)
    let maritalStatus = this.formValues("maritalStatus");
    const { DivorceLegalMatters } = this.formValues("maritalStatus");
    maritalStatus.DivorceLegalMatters = !!!DivorceLegalMatters ? (maritalStatus.DivorceLegalMatters = null) : DivorceLegalMatters;

    let data = {
      Branch: haveBranch.value,
      ...this.formValues("child"),
      ChildDateOfBirth: `${child.ChildYear}-${child.ChildMonth}-${child.ChildDay}`,
      ...this.formValues("dad"),
      ...this.formValues("mom"),
      ...maritalStatus,
      Siblings: this.SiblingsFormValues,
      Emergency: this.EmergencyFormValues,
      ...this.formValues("medical"),
      ...this.formValues("specialInfo"),
      ...this.formValues("submitOverlay"),
    };
    delete data.ChildMonth;
    delete data.ChildYear;
    delete data.ChildDay;
    this.props.entityStore.post(data);
  };
  getBranches = () => {
    this.props.entityStore.get({});
  };

  entityDidReceived(data) {
    let branches = [];
    const { setAppContext } = this.context;
    for (let i = 0; i < data.length; ++i) {
      branches[i] = { text: data[i].name, key: data[i].id, value: data[i].id };
    }
    setAppContext((oldObj) => ({ ...oldObj, branches }));
  }
  entityDidPosted() {
    window.location.reload(false);
  }
  entityDidCatch(data) {
    if (data) alert("validation errors in " + " : " + Object.keys(data));
  }

  onNext = (navigate = false) => {
    const { setAppContext } = this.context;
    let { isComplete, currentTabIndex } = this.context.appContextObj;
    // validate form
    let forms = ["child", "mom", "dad", "maritalStatus", "Siblings", "emergencyContact", "medical", "specialInfo"];
    for (let index = 0; index < forms.length; index++) {
      // if it's not the siblings form
      if (index !== 4) {
        if (!this.isFormValid(forms[index]) && (isComplete[index] !== 0 || navigate)) {
          isComplete[index] = 0;
          if (navigate) this.showFormErrors(forms[index]);
          setAppContext((oldObj) => ({ ...oldObj, currentTabIndex: index, isComplete }));
          break;
        } else if ((this.isFormValid(forms[index]) && isComplete[index] !== 1) || navigate) {
          isComplete[index] = 1;
          setAppContext((oldObj) => ({ ...oldObj, isComplete }));
          if (navigate) {
            setAppContext((oldObj) => ({ ...oldObj, currentTabIndex: index + 1, isComplete }));
          }
        }
      } // if it's the siblings form
      else {
        if (!this.isSiblingsFormValid() && (isComplete[index] !== 0 || navigate)) {
          isComplete[index] = 0;
          if (navigate) this.showSiblingsErrors();
          setAppContext((oldObj) => ({ ...oldObj, currentTabIndex: index, isComplete }));
          break;
        } else if (this.isSiblingsFormValid() && (isComplete[index] !== 1 || navigate)) {
          isComplete[index] = 1;
          setAppContext((oldObj) => ({ ...oldObj, isComplete }));
          if (navigate) {
            setAppContext((oldObj) => ({ ...oldObj, currentTabIndex: index + 1, isComplete }));
          }
        }
      }
    }
    // if user press next/submit
    if (navigate) {
      // check if all forms are valid
      let showSubmitOverlay = true;
      for (let i = 0; i < isComplete.length; ++i) {
        showSubmitOverlay &= isComplete[i];
      }
      if (currentTabIndex === 7 && showSubmitOverlay) {
        setAppContext((oldObj) => ({ ...oldObj, showSubmitOverlay }));
      } else if (currentTabIndex !== 7 && showSubmitOverlay) {
        setAppContext((oldObj) => ({ ...oldObj, currentTabIndex: 7 }));
      }
    }
  };

  // siblingsForm validation
  isSiblingsFormValid = () => {
    const { SiblingsForm } = this.context.appContextObj;
    for (let i = 0; i < SiblingsForm.length; ++i) {
      for (let object of SiblingsForm[i].inputs) {
        if (object.value === "" || object.value.length < 3) {
          return false;
        }
      }
      for (let object of SiblingsForm[i].dropDown) {
        if (object.value === "") {
          return false;
        }
      }
    }
    return true;
  };
  showSiblingsErrors = () => {
    const { setAppContext } = this.context;
    let SiblingsForm = [...this.context.appContextObj.SiblingsForm];
    for (let i = 0; i < SiblingsForm.length; ++i) {
      for (let j = 0; j < SiblingsForm[i].inputs.length; ++j) {
        let field = SiblingsForm[i].inputs[j];
        let valid = true;
        let massage = "";
        if (field.value.split("").length < 3) {
          valid = false;
          massage = "** This field is required.";
        }
        if (field.value.split("").length >= 1 && field.value.split("").length < 3) {
          valid = false;
          massage = "** Minimum length is 3";
        }
        field.error = !valid;
        field.helperText = massage;
      }
      for (let j = 0; j < SiblingsForm[i].dropDown.length; ++j) {
        let field = SiblingsForm[i].dropDown[j];
        let valid = true;
        let massage = "";
        if (field.value.length === 0) {
          valid = false;
          massage = "** This field is required.";
        }
        field.error = !valid;
        field.helperText = massage;
      }
    }
    setAppContext((oldObj) => ({
      ...oldObj,
      SiblingsForm,
    }));
  };

  //fixing the data for submission
  get SiblingsFormValues() {
    const { SiblingsForm } = this.context.appContextObj;
    let values = [];
    for (let i = 0; i < SiblingsForm.length; ++i) {
      let day = SiblingsForm[i].dropDown[0].value;
      let month = SiblingsForm[i].dropDown[1].value;
      let year = SiblingsForm[i].dropDown[2].value;
      let date = new Date(year, month, day, 0, 0, 0, 0);
      values[i] = {
        SiblingFirstName: SiblingsForm[i].inputs[0].value,
        SiblingMiddleName: SiblingsForm[i].inputs[1].value,
        SiblingLastName: SiblingsForm[i].inputs[2].value,
        SiblingDateOfBirth: date,
      };
    }
    return values;
  }
  get EmergencyFormValues() {
    let values = [];
    let formValues = this.formValues("emergencyContact");
    values[0] = {
      EmergencyName: formValues.name1,
      EmergencyRelation: formValues.relationToChild1,
      EmergencyMobileNumber: formValues.mobileNumber1,
    };
    values[1] = {
      EmergencyName: formValues.name2,
      EmergencyRelation: formValues.relationToChild2,
      EmergencyMobileNumber: formValues.mobileNumber2,
    };
    return values;
  }

  get getCurrentTab() {
    const { setAppContext } = this.context;
    const { currentTabIndex, isTouched } = this.context.appContextObj;
    if (!isTouched[currentTabIndex]) {
      isTouched[currentTabIndex] = 1;
      setAppContext((oldObj) => ({ ...oldObj, isTouched }));
    }
    switch (currentTabIndex) {
      case 0:
        return <Child thisParent={this} />;
      case 1:
        return <Mom thisParent={this} />;
      case 2:
        return <Dad thisParent={this} />;
      case 3:
        return <MaritalStatus thisParent={this} />;
      case 4:
        return <Siblings thisParent={this} />;
      case 5:
        return <EmergencyContact thisParent={this} />;
      case 6:
        return <Medical thisParent={this} />;
      case 7:
        return <Special thisParent={this} onSubmit={this.onSubmit} />;
      default:
        return <EmptyPage />;
    }
  }
  render() {
    const { setAppContext } = this.context;
    const { checked, haveBranch, showSubmitOverlay, submitting } = this.context.appContextObj;
    const { Input } = this;
    const { RelationToChild, Signature } = this.getAllState.submitOverlay;
    let disabled = checked && RelationToChild.errors.length === 0 && RelationToChild.value !== "" && Signature.errors.length === 0 && Signature.value !== "";
    return (
      <React.Fragment>
        <Suspense fallback={<LayoutSplashScreen />}>
          <Switch>
            <Route path="/" render={(props) => <Landing getBranches={this.getBranches} {...props} />} exact={true} />
            {!!!haveBranch ? <Redirect to="/" /> : <Layout onNext={() => this.onNext(true)}>{this.getCurrentTab}</Layout>}
          </Switch>
        </Suspense>
        <Modal style={{ color: "#000", top: 20, margin: 10 }} basic open={showSubmitOverlay} size="large">
          <Modal.Content>
            <p style={styles.infoText}>Please note that an application fee of EGP 1000 must be paid in order to complete your registration process at Trillium.</p>
            <p style={styles.infoText}>The application fee is non-refundable.</p>
            <div>
              <Input
                error={true}
                style={styles.inputContainer}
                labelStyle={styles.labelStyle}
                formType="submitOverlay"
                inputStyle={styles.overlayTextInput}
                label={"Signature :"}
                name={"Signature"}
                placeholder="Please write your full name"
              />
              <Input
                error={true}
                style={styles.inputContainer}
                labelStyle={styles.labelStyle}
                formType="submitOverlay"
                inputStyle={styles.overlayTextInput}
                label={"Relationship to child :"}
                name={"RelationToChild"}
                placeholder="Relationship to the child"
              />
              <Checkbox
                onChange={() => setAppContext((oldObj) => ({ ...oldObj, checked: !checked }))}
                checked={checked}
                style={{ marginTop: 20, color: "#e00909", fontSize: "18px" }}
                label="I hereby certify that all the above information is true and correct."
              />
            </div>
            <div style={{ display: "flex", flexDirection: "row", marginTop: 30 }}>
              <Button style={styles.ClosetButton} onClick={() => setAppContext((oldObj) => ({ ...oldObj, showSubmitOverlay: false }))} content="Close" />
              <Button loading={submitting} style={styles.SubmitButton} disabled={!disabled} onClick={this.onSubmit} content="Agree &#38; Submit" />
            </div>
          </Modal.Content>
        </Modal>
      </React.Fragment>
    );
  }
}

export default asEntity({ storeId: "Form" })(Routes);
