export default {
  input: { marginTop: "15px" },
  addButton: {
    padding: "20px 0",
    fontSize: "17px",
    color: "#FFF",
    border: 0,
    background: "#F05C63",
    margin: "20px",
    width: "97%",
    marginBottom: "100px",
  },
  close: {
    display: "flex",
    position: "absolute",
    right: "30px",
    width: "40px",
    height: "40px",
    backgroundColor: "#F05C63",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    color: "#FFF",
  },
};
