import React from "react";
import BDate from "Components/Layout/BDate";
import { Input, Frame, DropDown } from "../../../Components/Layout";
import { Button, Icon } from "semantic-ui-react";
import styles from "./styles";
import { generateDates } from "Services/Common";
const { Days, Months, Years } = generateDates();

export default function Siblings(props) {
  const siblingsData = {
    inputs: [
      {
        validators: ["isRequired", "minLength"],
        name: "SiblingFirstName",
        label: "First Name",
        value: "",
        error: false,
        helperText: "",
      },
      {
        validators: ["isRequired", "minLength"],
        name: "SiblingMiddleName",
        label: "Middle Name",
        value: "",
        error: false,
        helperText: "",
      },
      {
        validators: ["isRequired", "minLength"],
        name: "SiblingLastName",
        label: "Last Name",
        value: "",
        error: false,
        helperText: "",
      },
    ],
    dropDown: [
      { name: "Day", placeholder: "Day", list: Days, value: "", validators: ["isRequired"] },
      { name: "Month", placeholder: "Month", list: Months, value: "", validators: ["isRequired"] },
      { name: "Year", placeholder: "Year", list: Years, value: "", validators: ["isRequired"] },
    ],
  };
  const { appContextObj, setAppContext } = props.thisParent.context;
  const { isComplete } = appContextObj;
  const addSibling = () => {
    const sibling = appContextObj.SiblingsForm;
    let id = sibling.length > 0 ? sibling[sibling.length - 1].id + 1 : 0;
    setAppContext((oldObj) => ({
      ...oldObj,
      SiblingsForm: [...sibling, { ...siblingsData, id: id }],
    }));
  };
  const deleteSibling = (id) => {
    let newData = appContextObj.SiblingsForm.filter((object) => object.id !== id);
    setAppContext((oldObj) => ({
      ...oldObj,
      SiblingsForm: newData,
    }));
  };
  const handelChange = (index, inputIndex, value) => {
    let newData = [...appContextObj.SiblingsForm];
    let field = newData[index].inputs[inputIndex];
    let valid = true;
    let massage = "";
    isComplete[4] = 1;
    if (value.split("").length < 3) {
      valid = false;
      massage = "** This field is required.";
      isComplete[4] = 0;
    }

    if (value.split("").length >= 1 && value.split("").length < 3) {
      valid = false;
      massage = "** Minimum length is 3";
      isComplete[4] = 0;
    }
    if (value.split("").length >= 1 && value.split("").length > 20) {
      valid = false;
      massage = "** Maximum length is 20";
      isComplete[4] = 0;
    }
    field.error = !valid;
    field.helperText = massage;
    field.value = value;
    setAppContext((oldObj) => ({
      ...oldObj,
      SiblingsForm: newData,
      isComplete,
    }));
  };
  const handelDropDown = (index, DropDownIndex, value) => {
    let newData = [...appContextObj.SiblingsForm];
    let field = newData[index].dropDown[DropDownIndex];
    let valid = true;
    let massage = "";
    isComplete[4] = 1;
    if (value.length === 0) {
      valid = false;
      isComplete[4] = 0;
      massage = "** This field is required.";
    }
    field.error = !valid;
    field.helperText = massage;
    field.value = value;
    setAppContext((oldObj) => ({
      ...oldObj,
      SiblingsForm: newData,
      isComplete,
    }));
  };
  return (
    <div className="aaaa" style={{ marginBottom: 50, minHeight: "150vh" }}>
      <div style={{ background: "#fff", marginRight: 10, marginLeft: 10 }}>
        {appContextObj.SiblingsForm.length > 0 &&
          appContextObj.SiblingsForm.map((item, index) => {
            return (
              <div style={{ display: "block", paddingTop: "1.5px" }} key={"Sibling " + index}>
                <div
                  style={styles.close}
                  onClick={() => {
                    deleteSibling(item.id);
                  }}
                >
                  <Icon style={{ height: "auto" }} fitted name="close"></Icon>
                </div>
                <div style={{ margin: "15px" }}>
                  <Frame>
                    {item.inputs.map((object, inputIndex) => {
                      return <Input formType="Siblings" style={styles.input} {...object} key={inputIndex} onChange={(name, value) => handelChange(index, inputIndex, value)} />;
                    })}
                    <BDate label={"Birth Date"}>
                      {item.dropDown.map((object, DropDownIndex) => {
                        return <DropDown key={DropDownIndex} {...object} onChange={(name, value) => handelDropDown(index, DropDownIndex, value)} />;
                      })}
                    </BDate>
                  </Frame>
                </div>
              </div>
            );
          })}
        <Button fluid icon="plus" style={styles.addButton} onClick={addSibling} content="Add Siblings" />
      </div>
    </div>
  );
}
