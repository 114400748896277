export default {
  root: {
    minHeight: "150vh",
  },
  formContent: { backgroundColor: "#ffffff", border: "1px solid #F2F2F2", margin: 20, padding: 20, borderRadius: 10 },
  titleContent: { marginBottom: "20px" },
  title: {
    fontWeight: "600",
  },
  input: { marginTop: "15px" },
};
