import React from "react";
import styles from "./styles";

export default function Dad(props) {
  var data = [
    {
      label: "First Name",
      formType: "dad",
      name: "DadFirstName",
    },
    {
      label: "Middle Name",
      formType: "dad",
      name: "DadMiddleName",
    },
    {
      label: "Last Name",
      formType: "dad",
      name: "DadLastName",
    },
    {
      label: "Mobile Number",
      formType: "dad",
      name: "DadMobileNumber",
    },
    {
      label: "Email",
      formType: "dad",
      name: "DadEmail",
    },
    {
      label: "Graduated From",
      formType: "dad",
      name: "DadGraduatedFrom",
    },
    {
      label: "Occupation",
      formType: "dad",
      name: "DadOccupation",
    },
    {
      label: "Work Number",
      formType: "dad",
      name: "DadWorkNumber",
    },
  ];
  const { Input } = props.thisParent;
  return (
    <div style={styles.root}>
      <div style={{ marginRight: 10, marginLeft: 10, background: "#fff", marginBottom: 40 }}>
        {data.map((item, index) => {
          return <Input style={{ marginTop: "15px" }} {...item} />;
        })}
      </div>
    </div>
  );
}
