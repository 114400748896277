export default {
  root: (styles) => ({
    display: "flex",
    flexDirection: "column",
    ...styles,
  }),
  input: {
    height: "50px",
    width: "100%",
    borderColor: "transparent",
    borderBottomColor: "grey",
    borderWidth: 1,
    outline: "none",
  },
  errorText: {
    color: "#E84C3C",
  },
  label: {
    color: "#7F7F7F",
  },
};
