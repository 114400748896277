import React, { useState, useEffect } from "react";
import styles from "./styles";
import { Dropdown, Image, Button } from "semantic-ui-react";
import logo from "../../Images/logo.png";
import { useAppContext } from "../../Services/Providers/AppContextProvider";

export default function Landing(props) {
  const [disabled, setDisabled] = useState(true);
  const { appContextObj, setAppContext } = useAppContext();
  useEffect(() => {
    props.getBranches();
  }, []);
  const handleChange = (e, value) => {
    setAppContext((oldObj) => ({
      ...oldObj,
      haveBranch: value,
    }));
    setDisabled(false);
  };
  return (
    <div style={styles.root}>
      <Image style={styles.logo} src={logo} size="small" centered />
      <div style={styles.Dropdown}>
        <p style={styles.label}>Please Select Branch:</p>
        <Dropdown onChange={handleChange} placeholder="Select Branch" selection fluid options={appContextObj.branches} style={styles.selected} />
        <Button
          style={styles.startButton}
          disabled={disabled}
          onClick={() => {
            props.history.push("/admission");
          }}
          content="Tab here to start"
        />
      </div>
    </div>
  );
}
