import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import { AppContextProvider } from "./Services/Providers";
import Routes from "./Routes";

import createStore from "../src/Redux";
import { SplashScreenProvider } from "./Services/SplashScreen";

import "./App.css";

const store=createStore();

function App() {
  return (
    <Provider store={store}>
      <AppContextProvider>
        <SplashScreenProvider>
          <Router>
            <Routes />
          </Router>
        </SplashScreenProvider>
      </AppContextProvider>
    </Provider>
  );
}

export default App;
