export default {
  root: {
    height: "100vh",
    width: "100vw",
  },
  logo: {
    width: "270px",
    marginTop: "4%",
    // marginBottom: "5px",
  },
  Dropdown: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "15%",
    minWidth: "180px",
  },
  label: {
    // marginTop: "5px",
    // marginBottom: "20px",
    fontWeight: "bold",
    fontSize: "17px",
    textAlign: "center",
    color: "#000",
  },
  startButton: {
    marginTop: "5vh",
    padding: "15px 30px",
    fontSize: "16px",
    color: "#FFF",
    backgroundColor: "#F05C63",
    border: "none",
    borderRadius: "4px",
    width: "100%",
  },
  selected: {
    color: "black",
    fontWeight: "600",
    border: " 0px 0px 0px 0px",
  },
};
