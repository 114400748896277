const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export const generateDates = () => {
  let Days = [{ key: 0, text: "Day", value: "" }];

  let Months = [{ key: 0, text: "Month", value: "" }];

  let Years = [{ key: 0, text: "Year", value: "" }];

  for (let i = 1; i <= 31; i++) {
    Days.push({ key: i, text: i.toString(), value: i.toString() });
  }

  monthNames.forEach((month, index) => {
    Months.push({ key: index + 1, text: month, value: (index + 1).toString() });
  });

  let curYear = new Date().getFullYear();
  let stYear = curYear - 21;
  for (let i = 1; stYear++ < curYear; i++) {
    Years.push({ key: i, text: stYear.toString(), value: stYear.toString() });
  }

  return { Days, Months, Years };
};

export const ChildGender = [
  {
    key: 0,
    text: "Child's Gender",
    value: "",
  },
  {
    key: 1,
    text: "Male",
    value: "Male",
  },
  {
    key: 2,
    text: "Female",
    value: "Female",
  },
];

export const MaritalStatusList = [
  {
    key: 0,
    text: "Marital Status",
    value: "",
  },
  {
    key: 1,
    text: "Married",
    value: "Married",
  },
  {
    key: 2,
    text: "Divorced",
    value: "Divorced",
  },
  {
    key: 3,
    text: "Widowed",
    value: "Widowed",
  },
];
