export default {
  root: (styles) => ({
    flexDirection: "column",
    display: "flex",
    ...styles,
  }),
  input: (inputStyle) => ({
    height: "50px",
    width: "100%",
    borderColor: "transparent",
    borderBottomColor: "grey",
    borderWidth: 1,
    outline: "none",
    fontSize: "15px",
    fontFamily: "Roboto",
    ...inputStyle,
  }),
  errorText: {
    color: "#E84C3C",
  },
  label: (labelStyle) => ({
    fontWeight: "normal",
    fontSize: "16px",
    color: "#00000080",
    fontFamily: "Roboto",
    ...labelStyle,
    marginBottom: "1px",
  }),
  inputCounter: {
    width: "100%",
  },
  tail: {
    marginTop: "10px",
    fontSize: "16px",
    fontWeight: "400",
  },
};
