export default {
  root: (styles) => ({
    flexDirection: "column",
    display: "flex",
    ...styles,
  }),
  dropdown: (styles) => ({
    height: "30px",
    width: "35%",
    borderBottomColor: "black",
    borderWidth: 10,
    "border-bottom": "1px solid #F2F2F2",
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    ...styles,
  }),
  listItem: {
    cursor: "pointer",
  },

  errorText: {
    color: "#E84C3C",
  },
  label: {
    fontWeight: "normal",
    fontSize: "17px",
    color: "#00000080",
    fontFamily: "Roboto",
    marginTop: 15,
  },
  selected: {
    color: "black",
    fontWeight: "600",
    border: " 0px 0px 0px 0px",
    marginRight: "10px",
  },
};
