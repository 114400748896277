import axios from "axios";
import { create } from "apisauce";

import ApiConfigs from "../Configs/Api";

const axiosInstance = axios.create({
  baseURL: ApiConfigs.apiUrl,
  timeout: ApiConfigs.timeout,
  headers: {
    Accept: "application / json, text/ plain, */*",
    "Content-Type": "application/json",
  },
});

const Api = create({ axiosInstance: axiosInstance });
const CancelToken = axios.CancelToken;

const requestsSourceCancellation = CancelToken.source();

const endpoints = {
  Form: {
    submit: (data) => Api.post("/web/new-admission", data, { cancelToken: requestsSourceCancellation.token }),
  },
  branches: {
    getData: (data) => Api.get("/web/branch", {}, { cancelToken: requestsSourceCancellation.token }),
  },
};

export default endpoints;
