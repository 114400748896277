import React from "react";
import { MaritalStatusList } from "Services/Common";

import styles from "./styles";
import { isRequired, minLength } from "Services/Validators";

export default function MaritalStatus(props) {
  const { DropDown, Input } = props.thisParent;
  const handelChange = (object) => {
    if (object.value === "Divorced") {
      props.thisParent.addFormFields("maritalStatus", {
        DivorceLegalMatters: {
          type: props.thisParent.types.string,
          validators: [isRequired(), minLength(3)],
        },
      });
    } else {
      props.thisParent.removeFormFields("maritalStatus", ["DivorceLegalMatters"]);
    }
    return;
  };
  return (
    <div style={styles.root}>
      <div style={{ marginRight: 10, marginLeft: 10 }}>
        <DropDown
          formType="maritalStatus"
          style={styles.input}
          label={"Marital Status"}
          placeholder={"Marital Status"}
          name={"MaritalStatus"}
          list={MaritalStatusList}
          onChange={(obj) => handelChange(obj)}
        />
        <Input formType="maritalStatus" style={styles.input} label={"If so, custody is with ..."} name={"DivorceLegalMatters"} tail={"In case of limited custody, legal documents must be submitted"} />
      </div>
    </div>
  );
}
