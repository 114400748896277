export default {
  infoText: {
    textAlign: "left",
    fontSize: 22,
    margin: "15px 0px",
  },
  inputContainer: {
    flexDirection: "row",
    alignItems: "baseline",
    marginBottom: "20px",
  },
  labelStyle: {
    color: "#000",
    fontSize: "18px",
    minWidth: "25%",
  },
  overlayTextInput: {
    borderColor: "#000",
    background: "#fff0 ",
    textAlign: "center",
    width: "50%",
  },
  ClosetButton: {
    marginRight: "20px",
    borderRadius: "5px",
    border: "none",
    padding: "20px 60px",
    fontSize: "25px",
    color: "#fff",
    backgroundColor: "#aaa",
    width: "25%",
  },
  SubmitButton: {
    backgroundColor: "#F05C63",
    color: "#fff",
    borderRadius: "5px",
    border: "noe",
    padding: "20px 60px",
    fontSize: "25px",
    width: "75%",
  },
};
