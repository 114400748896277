import Types from "./Types";

export default class FieldState {
  constructor(args) {
    const { validateOn, ...restOptions } = args;

    return {
      showErrors: false,
      validators: [],
      validateOn: [...(validateOn || []), "value"],
      errors: [],
      value: "",
      type: Types.string,
      UIOnly: false,
      ...restOptions,
    };
  }
}
