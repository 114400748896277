import { put, call } from "redux-saga/effects";
import get from "lodash/get";
import api from "../../Services/Api";
import EntityActions from "../Actions/Entity";

export default {
  *get({ id, data }) {
    try {
      if (id === "Form") {
        const res = yield call(api.branches.getData, {});
        if (res.ok) {
          yield put(EntityActions.getSucceeded(id, res.data));
        } else {
          const errors = get(res, "data.errors[0].detail", "error");

          yield put(EntityActions.getFailed(id, errors));
        }
      }
    } catch (errors) {
      yield put(EntityActions.getFailed(id, "errorConnection"));
    }
  },
  *post({ id, data }) {
    try {
      if (id === "Form") {
        const res = yield call(api.Form.submit, data);
        // console.log("res", res);
        if (res.ok) {
          yield put(EntityActions.postSucceeded(id, res.data));
        } else {
          const errors = get(res, "data", "error");

          yield put(EntityActions.postFailed(id, errors));
        }
      }
    } catch (errors) {
      // console.log("SDD", errors);
      yield put(EntityActions.postFailed(id, "errorConnection"));
    }
  },
  *put({ id, data }) {
    //
  },
  *delete({ id, data }) {
    //
  },
};
