export default class Types {
	static number(something) {
		if (!something && something !== 0) {
			return "";
		}
		return Number(something);
	}

	static string(something) {
		return `${something}`;
	}

	static bool(something) {
		return Boolean(something);
	}
}
